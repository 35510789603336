import React, { useState, createContext } from "react"

export const defaultLayoutContext = {
  mobileNavVisible: false,
  toggleMobileNav: () => {},
  setMobileNavVisible: () => {},
  showCookieConsent: "byCookieValue",
  setShowCookieConsent: () => {},
}

const LayoutContext = createContext(defaultLayoutContext)

export const LayoutProvider = ({ children }) => {
  const [showCookieConsent, setShowCookieConsent] = useState(
    defaultLayoutContext.showCookieConsent
  )
  const [mobileNavVisible, setMobileNavVisible] = useState(
    defaultLayoutContext.mobileNavVisible
  )

  const toggleMobileNav = () => {
    setMobileNavVisible(!mobileNavVisible)
  }

  const layout = {
    mobileNavVisible,
    toggleMobileNav,
    setMobileNavVisible,
    showCookieConsent,
    setShowCookieConsent,
  }

  return (
    <LayoutContext.Provider value={layout}>{children}</LayoutContext.Provider>
  )
}

export default LayoutContext
